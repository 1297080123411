/* Objects */



/* Header */
.scroll-nav { overflow: hidden; }
.scroll-nav-list { margin: 0; }
.scroll-nav-item {
    overflow: hidden;
    display: table-cell !important;
}
.scroll-nav-link {
    .occupy;
    .push-3q;
    .pad-3q;
}
.header-logo, 
.footer-logo {
    .nospace;
    .center-block;
    .centered;
    .resetcoords;
    .layer-two;
    
    width: 10rem;
    height: 6.5rem;
}
.header-logo-link, 
.footer-logo-link {
    .occupy;
    height: 100%;
    
    text-indent: -999em;
    overflow: hidden;
    
    background: transparent url(../img/logo-white.png) no-repeat scroll center center;
    .retina-photo("../img/logo-white-2x.png");
    background-size: auto 4.5rem;
}
.footer-logo {
    width: 3.5rem;
    height:2.4rem;
}
.footer-logo-link {
    background-image: url(../img/logo-only-white.png);
    .retina-photo("../img/logo-only-white-2x.png");
    background-position: center center;
    background-size: auto 2.3rem;
}



.header-nav {
    .resetcoords;
    a:hover {
        background: none;
        .mustard;
    }
}
.header-nav-home {
    float: left;
    width: 20%;
    .centered;
    .push-1q;
    .space-1q;
    .bold;
    border-right: 1px solid rgba(0,0,0,0.2);
}
.header-nav-toggle {
    float: none;
    left: 20%;
    width: 30%;
    .freeflow;
    .freeflow.flushtop;
    .centered;
    .push-1q;
    .space-1q;
    border-left:  1px solid rgba(255,255,255,0.1);
}
.header-nav-search {
    .freeflow;
    .freeflow.flushtop;
    .freeflow.flushright;
    width: 50%;
}
.header-nav-list {
    .clearboth;
    display: none;
    .nospace;
    .push-1q;
    .pad-3q;
}
.header-nav-item {
    border-bottom:  1px solid rgba(255,255,255,0.05);
    &:last-child,
    &:last-child > .header-nav-link {
        border-bottom: none;
    }
}
.header-nav-link {
    .occupy;
    .push-half;
    .pad-1q-left;
    border-bottom: 1px solid rgba(0,0,0,0.1);
}
@media(min-width: @xs-min) {
    .header-nav-list {
        columns: 2;
    }
    .header-nav-item {
        .inline-block;
        .stretch;
    }
}
@media(min-width: @sm-min) {
    .header-nav-toggle { .hidden; }
    .header-nav {
        display: flex;
    }
    .header-nav-home {
        width: auto;
        flex: 1;
        border: none;
        .has-no-flexbox & { width: 8%; }
    }
    .header-nav-list {
        columns: none;
        .clearnone;
        .nopush;
        .nopad;
        .has-no-flexbox & {
            .flushleft;
            width: 72%;
        }
        flex: 7;
        display: block !important;
        display: flex !important;
    }
    .header-nav-item {
        .occupy;
        width: auto;
        .has-no-flexbox & {
            .flushleft;
            width: percentage(1/7);
            width: ~'calc(1/7 * 100%)';
        }
        flex: 1;
        
        .space-1q;
        border: none;
        border-left: 1px solid rgba(0,0,0,0.2);
        
        &:last-child  { border-right: none; }
    }
    .header-nav-link {
        .centered;
        .nopad;
        .push-1q;
        // .nspace-1q;
        border: none;
        border-left:  1px solid rgba(255,255,255,0.1);
    }
    .header-nav-search {
        .has-no-flexbox & {
            .flushright;
            width: 20%;
        }
        flex: 2;
        width: auto;
        position: static;
    }
}



.toggle-scroll {
    &.nav-fixed {
        position: fixed;
        transition: transform 0.2s ease-in-out;
    }
    &.nav-down {
        transform: translateY(0%);
    }
    &.nav-up {
        transform: translateY(-100%);
    }
}
.nav-shadow { transition: all 0.1s ease-in-out; }
#instantclick-bar { background: @brand-mustard; }
.has-js #jump-to-top {
    position: fixed;
    bottom: 0;
    right:  0;
    margin: 0.5rem;
    z-index: 999;
    a {
        width: 1.5rem;
        height:1.5rem;
        background: fade(@brand-brown, 85%) url(../img/up.svg) no-repeat scroll center center;
        background-size: auto 80%;
        box-shadow: rgba(255,255,255,0.5) 0 0 2px;
        transition: background-color 0.4s ease-out;
        &:hover { background-color: @brand-brown; }
    }
}



.mainbar,
.sidebar {
    transition: all 0.3s;
}
@media(min-width: @sm-min) {
    .mainbar {
        .flushleft;
        width: 65%;
    }
    .sidebar {
        .flushright;
        width: 27%;
    }
    .mainbar.rightbar { .flushright; }
    .sidebar.leftbar  { .flushleft; }
}
@media(min-width: @md-min) {
    .mainbar { margin-right: 4%; }
    .sidebar { width: 25%; }
}
@media(min-width: @xl-min) {
    .mainbar { width: 75%; margin-right: 0; }
    .sidebar { width: 20%; }
}



/* Forms */

input.with-password-toggle {
    .pad-four-right;
}
button.password-toggle {
    .freeflow;
    .freeflow.flushright;
    .nofill;
    .noline;
    .calm;
    .width-four;
    .blue;
    .centered;
    top: 50%;
    margin-top: -0.62rem;
    
    &:focus,
    &:hover { .underline; }
    &:focus { outline: none; }
}

a.button,
.button {
    .fill-mustard;
    .all-caps;
    .noline;
    .edged;
    padding: 0.5em;
    border-bottom: 0.2em solid darken(@brand-mustard, 10%);
    
    &:hover { background-color: darken(@brand-mustard, 10%); }
}

.parsley-errors-list {
    .nospace;
}



/* Filter nav Bar */

.category-label, .event-label {
    display: none;
}
.selected { background-color: @ultrafaded; }

.dropdown-view {
    background-image: url(../img/down-arrow.svg);
    background-position: 97% 57%;
    background-size: 0.7rem;
    background-repeat: no-repeat;
}
.list-view {
    background-image: url(../img/list-icon.svg);
    background-position: 18% 50%;
    background-size: 0.8rem;
    background-repeat: no-repeat;
}
.calendar-view {
    background-image: url(../img/calender-icon.svg);
    background-position: 10% 54%;
    background-size: 1rem;
    background-repeat: no-repeat;
}
.add-view {
    background: url(../img/plus.svg) no-repeat scroll 0.3rem 55%;
    background-size: 1rem;
}



/* FAQ Toggles */

.question {
    transition: all 0.3s ease-out;
    padding-left: 1.25em;
    background-position: 0 0.2em;
    background-size: 0.9em;
    background-image: url(../img/plus.png);
    background-repeat: no-repeat;
}
.question-title {
    margin-left: -1em;
    padding-left: 1em;
    transition: color 0.3s ease-out, font-size 0.3s ease-out;
}
.question-title:hover {
    outline: 0.75rem solid fade(black, 10%);
    background-color: fade(black, 10%);
}
.question.toggle-active {
    .space-four-bottom;
    background-image: url(../img/minus.png);
    .question-title {
        .brown;
    }
}



/* Home Button */

.dispatches-list {
    a:hover {
        color: inherit !important;
        background: inherit;
        text-decoration: underline;
    }
    ul { margin-top: 0; }
    li + li {
        padding-top:1.25em;
        margin-top: 1.25em;
        border-top: 1px solid @silver;
    }
    li:after {
        content:" →";
    }
    figure {
        .center-block; 
        .centered ;

        &:first-child { .nospace; }
        img,
        iframe {
            max-width: 100%;
        }
    }
}



/* Calendar View */

.days {
    .push-1q-bottom;
    border-bottom: 2px solid black;
}
.single-day, .dates {
   width: 100%/7;
}
.single-day {
    font-size: 0.5rem;
    .calm;
    .all-caps;
}
.dates {
    .push-1q-top;
    .thin;
    font-size: 0.5rem;
}
.stack-1 {
    background-color: #B8E986;
    &:hover {
        background-color: #B8E986;
    }
}
.stack-2 {
    background-color: #D4D2D2;
    &:hover {
        background-color: #D4D2D2;
    }
}
.stack-3 {
    background-color: #F4CC8B;
    &:hover {
        background-color: #F4CC8B;
    }
}
.stack-4 {
    background-color: #D0A3D9;
    &:hover {
        background-color: #D0A3D9;
    }
}
.stack-5 {
    background-color: #C6C073;
    &:hover {
        background-color: #C6C073;
    }
}
.stack-6 {
    background-color: #CA818A;
    &:hover {
        background-color: #CA818A;
    }
}
.stack-7 {
    background-color: #DBC1A9;
    &:hover {
        background-color: #DBC1A9;
    }
}
.stack-8 {
    background-color: #92D9B3;
    &:hover {
        background-color: #92D9B3;
    }
}
.stack-9 {
    background-color: #AEC8E6;
    &:hover {
        background-color: #AEC8E6;
    }
}
.stack-10 {
    background-color: #C6D18B;
    &:hover {
        background-color: #C6D18B;
    }
}
.stack-11 {
    background-color: #CA818A;
    &:hover {
        background-color: #CA818A;
    }
}

.events {
    // &:last-child  { .space-line-bottom; }
    .occupy;
    height: 0.5rem;
    margin-left: -1px;
    //@media (min-width: @lg-min) { height: 0.7rem; }
}

.event-listings {
    time {
        .pad-3q;
        .push-3q;
        font-size: 0.6rem;
        .calm;
    }
    .event-title {
        h1 {
            .title-small;
            .calm;
        }
    }
}
.week-row {
    display: flex;
    margin-top: 0.5em;
    .dates {
        border-left: 2px solid @silver;
        span {
            .pad-1q-left;
        }
    }
}
.calendar-month {
    .space-two-bottom;
}

@media (min-width: @lg-min) {
    .event-listings {
        margin-top: 4.5rem;
        time {
            font-size: 0.5rem;
        }
    }
}


.close-btn {
    background: transparent url(../img/close.svg) no-repeat scroll;
    background-size: 1.2rem;
    background-position: 99% 70%;
}


.logo {
    .occupy;
    height: 4.5rem;
    
    text-indent: -999em;
    overflow: hidden;
    
    background: transparent url(../img/logo-white.png) no-repeat scroll center center;
    .retina-photo("../img/logo-white-2x.png");
    background-size: auto 4.5rem;
}
.logo-mustard {
    background-image: url(../img/logo-mustard.png);
}

.twitter-icon     { background-image: url(../img/twitter.png); }
.facebook-icon    { background-image: url(../img/facebook.png); }
.gplus-icon       { background-image: url(../img/google_plus.png); }
.rss-icon         { background-image: url(../img/rss.png); }
.youtube-icon     { background-image: url(../img/youtube.png); }
.linkedin-icon    { background-image: url(../img/linkedin.png); }
.instagram-icon   { background-image: url(../img/instagram.png); }
.whatsapp-icon    { background-image: url(../img/whatsapp.svg); }
.email-icon       { background-image: url(../img/email.svg); }
.telegram-icon    { background-image: url(../img/telegram.svg); }

.social-login {
    .inline-block;
    .pad-one-right;
    .pad-three-left;
    .push-3q;
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: 0.75rem center;
}

// Uses the Cluster layout from Every Layout
// https://every-layout.dev/layouts/cluster/
.share-box {
    display: flex;
    flex-wrap: wrap;
    margin: -0.25rem;
}
.share-item {
    margin: 0.25rem;
}

.share-icon {
    width:  1.5em;
    height: 1.5em;
    .background-contain;
}
.footer-icon    {
    .occupy;
    .push-1q;
    .ngap-1q-left;
    .gap-1q-right;
    padding-left: 2rem;
    min-height: 1.75rem;
    
    background-size: 1.5rem;
    background-repeat: no-repeat;
    background-position: 0.25rem center;
}

.event-list-day .events:hover .calendar-event-tooltip {
    .occupy;
}
.calendar-event-tooltip {
    display: none;
    z-index: 20;
    left: 0;
    bottom: 1rem;
    border-radius: .2rem;
    transform: translateX(-50%);
}

// Tableau Embed Adjustments
.tableauPlaceholder,
.tableauPlaceholder iframe {
    max-width: 100%;
}
