/* ========== */
/* = Layout = */
/* ========== */

/* natural box layout model */
* { -moz-box-sizing: border-box; -webkit-box-sizing: border-box; box-sizing: border-box; }



/*  Base  */

/* Ensure body and #page stretch the full height of the viewport */
html  { height: 100%; }
body  { height: 100%; }

body  { margin: 0 auto; }
ul    { padding-left: 0; }
.no-bullet,
ul    { list-style-type: none; }
dl dd { margin-left: 0; }
img   { vertical-align: middle; }




/*  Helpers  */

.flushleft          { float: left;  }
.flushright         { float: right; }
.noflush            { float: none;  }
.clearboth          { clear: both;  }
.clearleft          { clear: left;  }
.clearright         { clear: right; }
.clearnone          { clear: none !important;  }
.floaters(@width, @suffix) {
    @media (min-width: @width) {
        .flushleft-@{suffix}   { float: left;  }
        .flushright-@{suffix}  { float: right; }
        .noflush-@{suffix}     { float: none;  }
        .clearboth-@{suffix}   { clear: both;  }
        .clearleft-@{suffix}   { clear: left;  }
        .clearright-@{suffix}  { clear: right; }
        .clearnone-@{suffix}   { clear: none;  }
    }
}
.floaters(@xs-min, xs);
.floaters(@sm-min, sm);
.floaters(@md-min, md);
.floaters(@lg-min, lg);
.floaters(@xl-min, xl);
.floaters(@xxl-min, xxl);

.hide-to-xl,
.hide-to-xxl,
.hide-xs,
.hide-xs-inline     { display: none; }
@media (min-width: @sm-min) {
    .hide-xs        { display: block; }
    .hide-xs-inline { display: inline; }
    .hide-sm,
    .hide-sm-inline { display: none; }
}
@media (min-width: @md-min) {
    .hide-sm        { display: block; }
    .hide-sm-inline { display: inline; }
    .hide-md-plus,
    .hide-md,
    .hide-md-inline { display: none; }
}
@media (min-width: @lg-min) {
    .hide-md        { display: block; }
    .hide-md-inline { display: inline; }
    .hide-lg,
    .hide-lg-inline { display: none; }
}
@media (min-width: @xl-min) {
    .hide-to-xl,
    .hide-lg        { display: block; }
    .hide-lg-inline { display: inline; }
    .hide-xl,
    .hide-xl-inline { display: none; }
}
@media (min-width: @xxl-min) {
    .hide-to-xxl    { display: block; }
    .hide-xxl,
    .hide-xxl-inline{ display: none; }
}



.resetcoords        { position: relative; }
.freeflow           { position: absolute; }
.freeflow.flushleft { left: 0;     }
.freeflow.flushright{ right:0;     }
.freeflow.flushtop  { top:  0;     }
.freeflow.flushbottom{ bottom: 0;  }
.layer-base         { z-index: 0;  }
.layer-one          { z-index: 10; }
.layer-two          { z-index: 20; }
.layer-three        { z-index: 30; }
.freeflow.fill      {
    top:    0;
    left:   0;
    right:  0;
    bottom: 0;
    width:  auto;
    height: auto;
}
.freeflow.fill.center,
.freeflow.fill > .center { margin: auto; }

.aspect-2-3         { padding-bottom: percentage(3/2)  !important; }
.aspect-3-4         { padding-bottom: percentage(4/3)  !important; }
.aspect-square      { padding-bottom: 100.0%           !important; }
.aspect-4-3         { padding-bottom: percentage(3/4)  !important; }
.aspect-3-2         { padding-bottom: percentage(2/3)  !important; }
.aspect-16-9        { padding-bottom: percentage(9/16) !important; }
.aspect-16-7        { padding-bottom: percentage(7/16) !important; }
.aspect-16-5        { padding-bottom: percentage(5/16) !important; }
.aspects(@width, @suffix) {
    @media (min-width: @width) {
        .aspect-2-3-@{suffix}   { padding-bottom: percentage(3/2)  !important; }
        .aspect-3-4-@{suffix}   { padding-bottom: percentage(4/3)  !important; }
        .aspect-square-@{suffix}{ padding-bottom: 100.0%           !important; }
        .aspect-4-3-@{suffix}   { padding-bottom: percentage(3/4)  !important; }
        .aspect-3-2-@{suffix}   { padding-bottom: percentage(2/3)  !important; }
        .aspect-16-9-@{suffix}  { padding-bottom: percentage(9/16) !important; }
        .aspect-16-7-@{suffix}  { padding-bottom: percentage(7/16) !important; }
    }
}
.aspects(@sm-min, sm);
.aspects(@md-min, md);
.aspects(@lg-min, lg);
.aspects(@xl-min, xl);


.occupy             { display: block;  }
.inline             { display: inline; }
.inline-block       { display: inline-block; }
.center-block       { margin-left: auto; margin-right: auto; }
.right-block        { margin-left: auto; margin-right: 0;    }
.left-block         { margin-left: 0;    margin-right: auto; }

.flex               { display: flex; }
.flex-col           { flex-direction: column; }
.align-start        { align-items: flex-start; }
.align-center       { align-items: center; }
.flex-wrap          { flex-wrap: wrap; }
.order-first        { order: -999; }
.order-1            { order: 1; }
.order-2            { order: 2; }
.order-3            { order: 3; }
.order-4            { order: 4; }
.order-5            { order: 5; }
.order-last         { order: 999; }
.recurse-breakpoints({
    .noflex@{suffix} { display: block; }
});

.truncate           { text-overflow: ellipsis; overflow: hidden; white-space: nowrap; }
.nooverflow         { overflow: hidden; }
.forcewrap          { word-wrap: break-word; }

.contain-width  img { max-width:  100%; height: auto; width: auto; }
.contain-width  img.lazyautosizes { width: 100%; }
.contain-height img { max-height: 100%; width:  auto; }

/* lazysizes auto width calculation */
picture img[data-sizes='auto'] { display: block; width: 100%; }
picture img.w-auto[data-sizes="auto"] { width: auto; }

.background-contain,
.background-fill    {
    background-repeat: no-repeat;
    background-position: center center;
    background-attachment: scroll;
    background-size: cover;
}
.background-contain { background-size: contain; }


.center-vertical    {
    display: flex;
    flex-direction: column;
    justify-content: center;
}
.center-vertical-text { vertical-align: middle; }

.embed-youtube,
.embed-vimeo,
.embed-16-9,
.embed-21-29        { position: relative; overflow: hidden; height: 0; }
.embed-youtube      { padding-bottom: 60.94%;  } /* 640 x 390 */
.embed-vimeo,
.embed-16-9         { padding-bottom: 56.25%;  }
.embed-21-29        { padding-bottom: 138.09%; }
.embed-youtube iframe,
.embed-vimeo iframe,
.embed-16-9  iframe,
.embed-21-29 iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

.has-js .js-hidden,
.js-nonjs-hidden,
.no-js .nonjs-hidden { display: none; }
.no-js .nonjs-visible { display: block !important; }



/*  Widths  */

.recurse-breakpoints({
    .full@{suffix}          { width:100%; }
    .eighty@{suffix}        { width: 80%; }
    .seventyfive@{suffix},
    .threefourth@{suffix}   { width: 75%; }
    .seventy@{suffix}       { width: 70%; }
    .twothird@{suffix}      { width: 66%; width: ~'calc(2/3 * 100%)'; }
    .sixtyfive@{suffix}     { width: 65%; }
    .sixty@{suffix}         { width: 60%; }
    .half@{suffix}          { width: 50%; }
    .almosthalf@{suffix}    { width: 45%; }
    .forty@{suffix}         { width: 40%; }
    .third@{suffix}         { width: 33%; width: ~'calc(1/3 * 100%)'; }
    .thirty@{suffix}        { width: 30%; }
    .quarter@{suffix},
    .fourth@{suffix}        { width: 25%; }
    .fifth@{suffix}         { width: 20%; }
    .sixth@{suffix}         { width: 16%; width: ~'calc(1/6 * 100%)'; }
    .tenth@{suffix}         { width: 10%; }
    .fluid@{suffix}         { width: auto; }
});

.block-2of3,
.block-1of2,
.block-1of3,
.block-3of4         { width: 100%; }
.block-1of4         { width: 50%; }
.container-prose,
.container          {
    padding: 0 1rem;
    max-width: 36rem;
    .center-block;
}

.block-1of4:nth-child(odd)      { clear: both; }

@media (min-width: @xs-min) {
    .container,
    .container-prose,
    .container-xs   {
        padding: 0 1.5rem;
        max-width: none;
    }
}
@media (min-width: @sm-min) {
    .block-1of3,
    .block-1of4     { width: 50%; }
    
    .container-prose{ padding: 0 4rem; }
    .container,
    .container-sm   { padding: 0 1.5rem; }
    
    .block-1of2:nth-child(odd),
    .block-1of3:nth-child(odd),
    .block-1of4:nth-child(odd)      { clear: both; }
}
@media (min-width: @md-min) {
    .block-2of4     { width: 66%; width: calc(~"2/3 * 100%"); }
    .block-1of2,
    .block-2of3     { width: 50%; }
    .block-1of4     { width: 33%; width: calc(~"1/3 * 100%"); }
    
    .container-prose{ padding: 0 7rem; }
    .container,
    .container-sm,
    .container-md   { padding: 0 2.5rem; }
    
    .block-1of4:nth-child(odd)      { clear: none; }
    .block-1of4:nth-child(3n+1)     { clear: both; }
}
@media (min-width: @lg-min) {
    .container-prose{ padding: 0 10rem; }
    .container,
    .container-sm,
    .container-md,
    .container-lg   { padding: 0 3.5rem; }
    
    .block-3of4     { width: 75%; }
    .block-2of3     { width: 66%; width: calc(~"2/3 * 100%"); }
    .block-2of4     { width: 50%; }
    .block-1of3     { width: 33%; width: calc(~"1/3 * 100%"); }
    .block-1of4     { width: 25%; }
    
    .block-1of3:nth-child(odd),
    .block-1of4:nth-child(3n+1)     { clear: none; }
    .block-1of3:nth-child(3n+1),
    .block-1of4:nth-child(4n+1)     { clear: both; }
}
@media (min-width: @xl-min) {
    .container-prose{ padding: 0 10rem; }
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl   { padding: 0 2rem; }
}
@media (min-width: (@xxl-min)) {
    .container-prose{ padding: 0 12rem; }
    .container,
    .container-sm,
    .container-md,
    .container-lg,
    .container-xl   {
        .center-block;
        padding: 0 2rem;
        max-width: 80rem;
    }
}

.stretch            { width: 100% !important; }
.width-fourteen     { width:14rem !important; }
.width-twelve       { width:12rem !important; }
.width-ten          { width:10rem !important; }
.width-eight        { width: 8rem !important; }
.width-six          { width: 6rem !important; }
.width-five         { width: 5rem !important; }
.width-four         { width: 4rem !important; }
.width-three-half   { width:3.5rem!important; }
.width-three        { width: 3rem !important; }
.width-two          { width: 2rem !important; }
.width-one          { width: 1rem !important; }


.min-eight          { min-width: 8rem; }
.min-six            { min-width: 6rem; }
.min-two            { min-width: 2rem; }

.max-fourteen       { max-width:14rem; }
.max-twelve         { max-width:12rem; }
.max-six            { max-width: 6rem; }

.max-full           { max-width:100%; }
.max-ninety         { max-width: 90%; }
.max-eighty         { max-width: 80%; }
.max-sixty          { max-width: 60%; }
.max-half           { max-width: 50%; }
.max-fortyfive      { max-width: 45%; }
.max-forty          { max-width: 40%; }
.max-thirtyfive     { max-width: 35%; }
.max-third          { max-width: 30%; }
.max-fifth          { max-width: 20%; }

.measure-4words     { max-width: 12em; }
.measure-6words     { max-width: 18em; }
.measure-8words     { max-width: 24em; }
.measure-10words    { max-width: 32em; }
.measure-12words    { max-width: 36em; }
.measure-14words    { max-width: 42em; }
.measure-16words    { max-width: 48em; }

.recurse-breakpoints({
    .nomax@{suffix}     { max-width: none; }
});


/* Heights */

.height-full-container {
    display: flex;
    flex-direction: column;
}
.height-full {
    flex: 1;
    height: 100%;
}

.min-height-10l     { min-height: 15em;  }
.min-height-6l      { min-height: 9.0em; }
.min-height-5l      { min-height: 7.5em; }
.min-height-4l      { min-height: 6.0em; }
.min-height-3l      { min-height: 3.5em; }
.min-height-2l      { min-height: 2.5em; }
.min-height-1l      { min-height: 2.0em; }

.height-10l         { height: 15.0em; }
.height-9l          { height: 13.5em; }
.height-8l          { height: 12.0em; }
.height-7l          { height: 10.5em; }
.height-6l          { height: 9.0em; }
.height-5l          { height: 7.5em; }
.height-4l          { height: 6.0em; }
.height-3l          { height: 3.5em; }
.height-2l          { height: 2.5em; }
.height-1l          { height: 2.0em; }



/* Columns */

.cols-five,
.cols-four,
.cols-two {
    column-count: 2;
    column-gap: 1rem;
}
@media (min-width: @sm-min) {
    .cols-five,
    .cols-four,
    .cols-two,
    .cols-three-sm,
    .cols-two-sm {
        column-count: 2;
        column-gap: 2rem;
    }
    .cols-five,
    .cols-four {
        column-count: 3;
        column-gap: 0.5rem;
    }
    .nocols-sm { column-count: auto !important; }
}
@media (min-width: @md-min) {
    .cols-two-sm,
    .cols-three-sm,
    .cols-two-md {
        column-count: 2;
        column-gap: 3rem;
    }
    
    .cols-prose-two,
    .cols-prose-three {
        column-count: 2;
        column-gap: 2rem;
    }
    
    .cols-five,
    .cols-four-md,
    .cols-four {
        column-count: 4;
        column-gap: 0.5rem;
    }
    .nocols-md { column-count: auto !important; }
}
@media (min-width: @lg-min) {
    .cols-prose-three { column-count: 3; }
    .cols-four { column-gap: 1.5rem; }
    .cols-five { column-count: 5; }
    .nocols-lg { column-count: auto !important; }
}
@media (min-width: @xl-min) {
    .cols-three-sm { column-count: 3; }
}
.colsspan   { column-span: all; }
.nocolsgap  { column-gap: 0 !important; }
.nocolsbreak {
    overflow: hidden;                   /* Fix for firefox and IE 10-11  */
    -moz-column-break-inside: avoid;    /* Current Firefox */
    break-inside: avoid;                /* IE 10+ */
    break-inside: avoid-column;
}



/*  Margins  */
@names:  ~'1e', ~'1q', half, ~'3q', one, line, two, three, four, five, six;
@values: 0.125, 0.25, 0.5, 0.75, 1, 1.5, 2, 3, 4, 5, 6;

.margin(@i: 1) when (@i > 0) {
    .margin(@i - 1);
    @name: extract(@names, @i);
    @value: extract(@values, @i);
    .space-@{name}, .space-@{name}-top      { margin-top: @value+0rem !important; }
    .space-@{name}, .space-@{name}-bottom   { margin-bottom: @value+0rem !important; }
}
.margin(length(@values));
.nospace,           .nospace-top            { margin-top:    0       !important; }
.nospace,           .nospace-bottom         { margin-bottom: 0       !important; }

.nmargin(@i: 1) when (@i > 0) {
    .nmargin(@i - 1);
    @name: extract(@names, @i);
    @value: extract(@values, @i);
    .nspace-@{name}, .nspace-@{name}-top    { margin-top: -@value+0rem !important; }
    .nspace-@{name}, .nspace-@{name}-bottom { margin-bottom: -@value+0rem !important; }
}
.nmargin(length(@values));

.pad(@i: 1) when (@i > 0) {
    .pad(@i - 1);
    @name: extract(@names, @i);
    @value: extract(@values, @i);
    .pad-@{name}, .pad-@{name}-left         { padding-left: @value+0rem !important; }
    .pad-@{name}, .pad-@{name}-right        { padding-right: @value+0rem !important; }
}
.pad(length(@values));
.nopad,             .nopad-left             { padding-left:  0       !important; }
.nopad,             .nopad-right            { padding-right: 0       !important; }

.push(@i: 1) when (@i > 0) {
    .push(@i - 1);
    @name: extract(@names, @i);
    @value: extract(@values, @i);
    .push-@{name}, .push-@{name}-top        { padding-top: @value+0rem !important; }
    .push-@{name}, .push-@{name}-bottom     { padding-bottom: @value+0rem !important; }
}
.push(length(@values));
.nopush,            .nopush-top             { padding-top:    0       !important; }
.nopush,            .nopush-bottom          { padding-bottom: 0       !important; }

.gap(@i: 1) when (@i > 0) {
    .gap(@i - 1);
    @name: extract(@names, @i);
    @value: extract(@values, @i);
    .gap-@{name}, .gap-@{name}-left         { margin-left: @value+0rem !important; }
    .gap-@{name}, .gap-@{name}-right        { margin-right: @value+0rem !important; }
}
.gap(length(@values));
.nogap,             .nogap-left             { margin-left:  0 !important; }
.nogap,             .nogap-right            { margin-right: 0 !important; }

.ngap(@i: 1) when (@i > 0) {
    .ngap(@i - 1);
    @name: extract(@names, @i);
    @value: extract(@values, @i);
    .ngap-@{name}, .ngap-@{name}-left       { margin-left: -@value+0rem !important; }
    .ngap-@{name}, .ngap-@{name}-right      { margin-right: -@value+0rem !important; }
}
.ngap(length(@values));


.recurse-breakpoints({
    .nogap@{suffix},
    .nogap-left@{suffix}        { margin-left:    0 !important; }
    .nogap@{suffix},
    .nogap-right@{suffix}       { margin-right:   0 !important; }
    
    .nospace@{suffix},
    .nospace-top@{suffix}       { margin-top:     0 !important; }
    .nospace@{suffix},
    .nospace-bottom@{suffix}    { margin-bottom:  0 !important; }
    
    .pad-two@{suffix},
    .pad-two-left@{suffix}      { padding-left:   2rem !important; }
    .pad-two@{suffix},
    .pad-two-right@{suffix}     { padding-right:  2rem !important; }
    .nopad@{suffix},
    .nopad-left@{suffix}        { padding-left:   0 !important; }
    .nopad@{suffix},
    .nopad-right@{suffix}       { padding-right:  0 !important; }
    
    .push-two@{suffix},
    .push-two-top@{suffix}      { padding-top:    2rem !important; }
    .push-two@{suffix},
    .push-two-bottom@{suffix}   { padding-bottom: 2rem !important; }
    .nopush@{suffix},
    .nopush-top@{suffix}        { padding-top:    0 !important; }
    .nopush@{suffix},
    .nopush-bottom@{suffix}     { padding-bottom: 0 !important; }
});
