/* ========= */
/* = Theme = */
/* ========= */



/* Base Styles */

body      { color: @body-text-colour; }


a {
    text-decoration: none;
    color: inherit;
    transition: background-color 0.1s ease-out;
}
a:visited { color: auto; }
a:not(.silent-hover):hover {
    text-decoration: none;
    color: @white !important;
    background-color: @black;
    background-color: fade(@black, 50%);
}
a.no-underline {
    text-decoration: none;
}
.underline-hover a:hover,
a.underline-hover:hover {
    text-decoration: underline;
}
a.light-hover:hover   {
    color: inherit !important;
    background-color: fade(@black, 10%);
}
.highlight-links a {
    color: @brand-mustard !important;
}

hr {
    display: block; clear: both; height: 0; margin: 0; padding: 0;

    border: none;
    border-top: 1px solid @black;
    border-top: 1px solid fade(@black, 10%);
    border-bottom: 1px solid @white;
    border-bottom: 1px solid fade(@white, 20%);

    //filter: alpha(opacity = 30);
}



/* Colours */
@dark         : darken(@body-text-colour, 7%);
@slightlyfaded: fade(@body-text-colour, 73%);
@faded        : fade(@body-text-colour, 57%);
@ultrafaded   : fade(@body-text-colour, 18%);
@light        : lighten(@body-text-colour, 73%);

// Grays
.black          { color: @black         !important; }
.text           { color: @body-text-colour !important; }
.dark           { color: @dark          !important; }
.gray           { color: @gray          !important; }
.silver         { color: @silver        !important; }
.light          { color: @light         !important; }
.slightlyfaded  { color: @slightlyfaded !important; }
.faded          { color: @faded         !important; }
.ultrafaded     { color: @ultrafaded    !important; }
.white          { color: @white         !important; }

// Brand
.tangerine,
.mustard,
.mustard-original{color: @brand-mustard !important; }
.mustard-text   { color: @brand-mustard-text !important; }
.brown          { color: @brand-brown   !important; }
.brown-light    { color: @brand-brown-light !important; }
.olive          { color: @brand-olive   !important; }

// Others
.red            { color: @red       !important; }
.green          { color: @green     !important; }
.yellow         { color: @yellow    !important; }
.blue           { color: @blue      !important; }
.mblue          { color: @mblue     !important; }
.purple         { color: @purple    !important; }
.green-dark     { color: @green-dark!important; }

// Backgrounds
body,
.fill-white      { background-color: #FFFFFF; color: @body-text-colour; }
.fill-light      { background-color: @light;  color: @body-text-colour; }
.fill-black      { background-color: #000000; color: @light; }
.fill-dark       { background-color: #444444; color: #F0F0F0; }
.fill-light      { background-color: #EEEEEE; color: #333333; }
.fill-red        { background-color: @red; color: @body-text-colour; }
.fill-green      { background-color: @green; color: @green-dark; }

// Brand
.fill-mustard   { background-color: @brand-mustard; color: white; }
.fill-brown     { background-color: @brand-brown;   color: white; }
.fill-brown-dark{ background-color: darken(@brand-brown, 15%); color: white; }
.fill-olive     { background-color: @brand-olive;   color: white; }

.fill-dark {
    .faded {
        color: lighten(#444444, 20%) !important;
    }
}

.faded {
    a { color: @blue; }
}

.fill-hud-white95  { background-color: fade(white, 95%); color: @body-text-colour; }
.fill-hud-white    { background-color: fade(white, 50%); color: @body-text-colour; }
.fill-hud-black    {
    background-color: darken(@brand-brown, 12.5%);
    background-color: fade(black, 35%);
    color: @light;
}

.nofill          { background-color: transparent; color: inherit; }



/* Borders and Shadows */

.lifted {
   box-shadow: rgba(0,0,0,0.4) 0 0 0.4rem;
}
.rounded                    { border-radius: 50%; }
.edged                      { border-radius: 0; }
abbr[title].noline,
.noline                     { border: none; }
.lined                      { border:        1px solid; }
.line-top                   { border-top:    1px solid; }
.line-bottom                { border-bottom: 1px solid; }
.line-left                  { border-left:   1px solid; }
.line-right                 { border-right:  1px solid; }
.line-small-top             { border-top:    3px solid; }
.line-small-bottom          { border-bottom: 3px solid; }
.line-small-left            { border-left:   3px solid; }
.line-small-right           { border-right:  3px solid; }
.line-top-faded             { border-top:    1px solid @faded; }
.line-bottom-faded          { border-bottom: 1px solid @faded; }
.lined-faded                { border:        1px solid @faded; }
.line-top-ultrafaded        { border-top:    1px solid @ultrafaded; }
.line-bottom-ultrafaded     { border-bottom: 1px solid @ultrafaded; }
.lined-ultrafaded           { border:        1px solid @ultrafaded; }
.line-silver                { border:        1px solid @silver; }
.line-top-silver            { border-top:    1px solid @silver; }
.line-bottom-silver         { border-bottom: 1px solid @silver; }
.line-left-silver           { border-left:   1px solid @silver; }
.line-right-silver          { border-right:  1px solid @silver; }
.line-left-faded           { border-left:   1px solid lighten(#444444, 5%); }
.line-right-faded          { border-right:  1px solid lighten(#444444, 5%); }
.lined-white                { border:        1px solid white; }
.line-top-white             { border-top:    1px solid white; }
.line-bottom-white          { border-bottom: 1px solid white; }
.line-left-white            { border-left:   1px solid white; }
.line-right-white           { border-right:  1px solid white; }
.noline-left                { border-left:   none; }
.noline-right               { border-right:  none; }
.noline-bottom              { border-bottom: none; }
.noline-top                 { border-top:    none; }
.hr-top-ultrafaded:before,
.hr-bottom-mustard:after {
    border-bottom: 1px solid @brand-mustard;
    content: "";
    display: block;
    width: 1.5rem;
    margin-top: 0.4rem;
}
.hr-top-ultrafaded:before {
    border-bottom-color: @ultrafaded;
    margin-top: 0;
    margin-bottom: 0.8rem;
}

.stacked-one {
    box-shadow:
        #EEE 1px 1px 0,
        2px 2px 0;
}
.stacked-two {
    box-shadow:
        #EEE 1px 1px 0,
        2px 2px 0,
        #EEE 3px 3px 0,
        4px 4px 0;
}
.stacked-three {
    box-shadow:
        #EEE 1px 1px 0,
        2px 2px 0,
        #EEE 3px 3px 0,
        4px 4px 0,
        #EEE 5px 5px 0,
        6px 6px 0;
}
.stacked-four {
    box-shadow:
        #EEE 1px 1px 0,
        2px 2px 0,
        #EEE 3px 3px 0,
        4px 4px 0,
        #EEE 5px 5px 0,
        6px 6px 0,
        #EEE 7px 7px 0,
        8px 8px 0;
}
.stacked-horizontal {
    box-shadow:
        #EEE -1px 0 0,
        -2px 0 0,
        #EEE -3px 0 0,
        -4px 0 0,
        #EEE -5px 0 0,
        -6px 0 0,
        #EEE -7px 0 0,
        -8px 0 0,
        #EEE -9px 0 0,
        -10px 0 0,
        #EEE -11px 0 0,
        -12px 0 0;
}



/* Retina Logo */
.retina-photo(@file-2x) {
    @media (-webkit-min-device-pixel-ratio: 1.5),
           (min--moz-device-pixel-ratio: 1.5),
           (min-resolution: 1.5dppx),
           (min-resolution: 144dpi) 
    {
        background-image: url("@{file-2x}");
    }
}



/* Smooth scrolling */
.smooth-scrolling {
    scroll-behavior: smooth;
}
