/* ==|== print styles =======================================================
   Print styles.
   Inlined to avoid required HTTP connection: h5bp.com/r
   ========================================================================== */
 
@media print {
    *,
    *:before,
    *:after {
        // background: transparent !important;
        color: #000 !important; /* Black prints faster:
                                   http://www.sanbeiji.com/archives/953 */
        box-shadow: none !important;
        text-shadow: none !important;
    }
    
    .prose-story {
        a,
        a:visited {
            text-decoration: underline !important;
            background: none !important;
        }
    }
    a[href] {
        &.expand-print,
        .prose &,
        .prose-story & {
            &:after {
                content: " (" attr(href) ")";
                font-style: italic;
            }
        }
    }
    
    /*
     * Don't show links that are fragment identifiers,
     * or use the `javascript:` pseudo protocol
     */
    
    a[href*="indiabioscience.org"],
    a[href^="#"],
    a[href^="javascript:"] {
        text-decoration: none !important;
        background-color: transparent !important;
        &:after { display: none; }
    }
    
    pre {
        white-space: pre-wrap !important;
    }
    pre,
    blockquote {
        border: 1px solid #999;
        page-break-inside: avoid;
    }
    
    // Colours
    .black,
    .dark {
        color: black !important;
    }
    .brown,
    .brown-light {
        color: @brand-brown !important;
    }
    .slightlyfaded,
    .silver,
    .light,
    .gray {
        color: #666 !important;
    }
    .faded,
    .ultrafaded {
        color: #999 !important;
    }
    .fill-black,
    .fill-dark,
    .fill-brown {
        background-color: @light !important;
    }
    
    
    /*
     * Printing Tables:
     * http://css-discuss.incutio.com/wiki/Printing_Tables
     */
    
    thead {
        display: table-header-group;
    }
    
    tr,
    img {
        page-break-inside: avoid;
    }
    
    p,
    h2,
    h3 {
        orphans: 3;
        widows: 3;
    }
    
    h2,
    h3 {
        page-break-after: avoid;
    }
    
    .nav-shadow,
    .hide-print {
        display: none;
    }
    .nav-fixed {
        position: static !important;
    }
    .site-wide-footer {
        background: transparent !important;
        nav {
            margin-right: 0 !important;
            padding-top: 1rem;
            border-top: 1px solid;
        }
    }
}
