/* ============== */
/* = Typography = */
/* ============== */



// Font serving on Retina devices
.retina-font-weight(@font-weight; @important:~'') {
    @media (-webkit-min-device-pixel-ratio: 1.5),
           (min--moz-device-pixel-ratio: 1.5),
           (min-resolution: 1.5dppx),
           (min-resolution: 144dpi)
    {
        & { font-weight: @font-weight @important; }
    }
}



/*  Families  */

body {
    font-family: @title-stack;
    line-height: @title-leading;
    font-weight: normal;
}
.webfont-active body {
    font-family: @title-webfont-stack;
}

h1,
h2,
h3,
h4,
dt,
th,
label,
input[type=submit],
input[type=button],
button,
.button {
    font-family: @title-stack;
    line-height: @title-leading;
    font-weight: bold;
}
.webfont-active {
    h1,
    h2,
    h3,
    h4,
    dt,
    th,
    label,
    input[type=submit],
    input[type=button],
    button,
    .button {
        font-family: @title-webfont-stack;
    }
}

/* Declaring it separately to allow it to override the defaults declared just above */
.prose-type {
    font-family: @prose-stack;
    font-weight: normal !important;
}
.webfont-active .prose-type {
    font-family: @prose-webfont-stack;
}
.title-type {
    font-family: @title-stack;
    font-weight: bold !important;
}
.title-type-only {
    font-family: @title-stack;
}
.webfont-active .title-type-only,
.webfont-active .title-type {
    font-family: @title-webfont-stack;
}

pre,
code,
.monospace { font-family: Menlo, Monaco, Consolas, Bitstream Vera Sans Mono, monospace; }



/*  Scale  */

html                    { font-size: 18px;     line-height: @prose-leading; }

// Title scale (mostly) based on 1.333 (Perfect Fourth)
.title-huge             { font-size: 2.369rem; line-height: 1.15; }
.title-extralarge       { font-size: 1.777rem; line-height: @title-leading; }
.title-large            { font-size: 1.333rem; line-height: @title-leading; }
.title-medium           { font-size: 1.150rem; line-height: @title-leading; }
.title-regular          { font-size: 1.000rem; line-height: @title-leading; }
.title-small            { font-size: 0.833rem; line-height: @title-leading; }
.title-tiny             { font-size: 0.778rem; line-height: @title-leading; }

// Prose scale based on 1.200 (Minor Third)
.prose-large            { font-size: 1.200rem; line-height: @prose-leading; }
.prose-regular          { font-size: 1.000rem; line-height: @prose-leading; }
.prose-small            { font-size: 0.889rem; line-height: @prose-leading; }
.prose-tiny             { font-size: 0.694rem; line-height: @prose-leading; }

// Redesign type scale
.text-11-13             { font-size: (11/18 * 1rem); line-height: (13/11); }
.text-12-14             { font-size: (12/18 * 1rem); line-height: (14/12); }
.text-13-13             { font-size: (13/18 * 1rem); line-height: (13/13); }
.text-13-15-dyn,
.text-13-15             { font-size: (13/18 * 1rem); line-height: (15/13); }
.text-13-16             { font-size: (13/18 * 1rem); line-height: (16/13); }
.text-14-16             { font-size: (14/18 * 1rem); line-height: (16/14); }
.text-14-17             { font-size: (14/18 * 1rem); line-height: (17/14); }
.text-15-16-dyn,
.text-15-16             { font-size: (15/18 * 1rem); line-height: (16/15); }
.text-15-17             { font-size: (15/18 * 1rem); line-height: (17/15); }
.text-15-18             { font-size: (15/18 * 1rem); line-height: (18/15); }
.text-16-18             { font-size: (16/18 * 1rem); line-height: (18/16); }
.text-16-19-dyn,
.text-16-19             { font-size: (16/18 * 1rem); line-height: (19/16); }
.text-16-20-dyn,
.text-16-20             { font-size: (16/18 * 1rem); line-height: (20/16); }
.text-17-24             { font-size: (17/18 * 1rem); line-height: (24/17); }
.text-18-20             { font-size: (18/18 * 1rem); line-height: (20/18); }
.text-18-22             { font-size: (18/18 * 1rem); line-height: (22/18); }
.text-18-23             { font-size: (18/18 * 1rem); line-height: (23/18); }
.text-18-24             { font-size: (18/18 * 1rem); line-height: (24/18); }
.text-18-26             { font-size: (18/18 * 1rem); line-height: (26/18); }
.text-21-24-dyn,
.text-21-24             { font-size: (21/18 * 1rem); line-height: (24/21); }
.text-24-26             { font-size: (24/18 * 1rem); line-height: (26/24); }
.text-25-27             { font-size: (25/18 * 1rem); line-height: (27/25); }
.text-32-39             { font-size: (32/18 * 1rem); line-height: (39/32); }

@media (min-width: @xs-min) {
    html                { font-size: 18px;     line-height: @prose-leading; }
}
@media (min-width: @sm-min) {}
@media (min-width: @md-min) {
    // html                { font-size: 19px;     line-height: @prose-leading; }
    
    // // Title scale based on 1.5 (Perfect Fifth)
    // .title-huge              { font-size: 3.000rem; }
    // .title-extralarge        { font-size: 2.250rem; }
    // .title-large             { font-size: 1.500rem; }
    // .title-medium            { font-size: 1.200rem; }
    // .title-small             { font-size: 0.800rem; }
    // .title-tiny              { font-size: 0.667rem; }
    
    // // Prose scale based on 1.25 (Major Third)
    // .prose-large        { font-size: 1.250rem; }
    // .prose-regular      { font-size: 1.000rem; }
    // .prose-small        { font-size: 0.800rem; }
    // .prose-tiny         { font-size: 0.640rem; }
}
@media (min-width: @lg-min) {
    // html                { font-size: 21px;     line-height: @prose-leading; }
    .text-13-15-dyn      { font-size: (16/18 * 1rem); line-height: (18/16); }
    .text-15-16-dyn      { font-size: (18/18 * 1rem); line-height: (20/18); }
    .text-16-19-dyn      { font-size: (24/18 * 1rem); line-height: (28/24); }
    .text-16-20-dyn      { font-size: (18/18 * 1rem); line-height: (26/18); }
    .text-21-24-dyn      { font-size: (28/18 * 1rem); line-height: (30/28); }
}
@media (min-width: @xl-min) {
    // html                { font-size: 21px;     line-height: @prose-leading; }
}
@media (min-width: @xxl-min) {
    // html                { font-size: 22px;     line-height: @prose-leading; }
}



/*  Helpers  */

.dense-leading  { line-height: @title-leading; }
.prose-leading  { line-height: @prose-leading; }
.mid-leading    { line-height: 1.2222223; }
.title-leading  { line-height: @title-leading; }

.centered       { text-align: center; }
.right-aligned  { text-align: right; }
.left-aligned   { text-align: left; }
.recurse-breakpoints({
    .centered@{suffix}       { text-align: center; }
    .right-aligned@{suffix}  { text-align: right; }
    .left-aligned@{suffix}   { text-align: left; }
});
.justified      {
    text-align: justify;
-webkit-hyphens: auto;
   -moz-hyphens: auto;
        hyphens: auto;
}

.ultrathin      { font-weight: 100 !important; .retina-font-weight(300, ~'!important'); }
.thin           { font-weight: 300 !important; .retina-font-weight(400, ~'!important'); }
.calm           { font-weight: 400 !important; }
.semibold       { font-weight: 600 !important; }
.bold           { font-weight: 700 !important; .retina-font-weight(900, ~'!important'); }
.heavy          { font-weight: 900 !important; }
.italic         { font-style: italic; }
.no-italic      { font-style: normal; }

.all-caps       { text-transform: uppercase; }
.all-lower      { text-transform: lowercase; }
.reset-caps     { text-transform: none; }
.strike         { text-decoration: line-through; }
.underline      { text-decoration: underline; }
.no-underline   { text-decoration: none; }
.letter-spaced  { letter-spacing: 0.15em; }

.tracking-one   { letter-spacing:  0.10em }
.tracking-3q    { letter-spacing:  0.07em }
.tracking-half  { letter-spacing:  0.05em }
.tracking-1q    { letter-spacing:  0.025em }
.tracking-1e    { letter-spacing:  0.01em }
.ntracking-1e   { letter-spacing: -0.01em }
.ntracking-1q   { letter-spacing: -0.025em }
.reset-tracking { letter-spacing: auto; }

.quo            { margin-left: -0.24em; }
.dquo           { margin-left: -0.44em; }
.tquo           { margin-left: -0.68em; }



/* Prose */

.prose {
    // hyphens: auto;
    text-rendering: optimizeLegibility;
    
    a { text-decoration: underline; }
    a:not(:hover) {
        text-decoration: none;
        background-image: linear-gradient(to bottom, transparent 50%, fade(black, 50%) 50%);
        background-size: 2px 2px;
        background-repeat: repeat-x;
        background-position: 0 97%;
    }
    &.fill-brown a:not(:hover) {
        background-image: linear-gradient(to bottom, transparent 50%, fade(white, 50%) 50%);
    }
    a.no-underline {
        background-image: none;
    }
    
    
    h1,
    h2,
    h3,
    h4 {
        // font-family: inherit !important;
        margin-bottom: 0.25em;
    }
    h1:first-child,
    h2:first-child,
    h3:first-child,
    h4:first-child {
        .nospace-top;
    }
    h1:not(:first-child),
    h2:not(:first-child),
    h3:not(:first-child),
    h4:not(:first-child) {
        .space-three-top;
    }
    h1 {
        .title-extralarge;
    }
    h2 {
        .title-large;
    }
    h3 {
        .title-small;
        .all-caps;
    }
    h4 { .title-small; }
    
    p,
    ul,
    ol,
    dl {
        margin: 0 0 0.75em;
    }
    
    blockquote {
        font-size: 0.9em;
        font-style: italic;
        border-left: 0.1em solid;
        .nogap;
        padding: 0 1.5em;
    }
    
    .pull-quote {
        font-size: 1.5em;
        border-left: none;
        padding: 0 0.75em;
        &:before {
            content: "“";
            font-size: 2.5em;
            margin: -0.4em;
            position: absolute;
            color: @brand-mustard-text;
        }
        @media (min-width: @sm-min) {
            margin-left: -42.8% !important;
        }
        @media (min-width: @lg-min) {
            margin-left: 0 !important;
            padding: 0.5em 2em;
        }
    }
    
    ol,
    ul           { padding-left: 1.5em; }
    li           { margin-bottom:0.25em; }
    li li        { margin-left:  0.25em; }
    ol           { list-style-type: decimal; }
    ul           { list-style-type: disc; }
    ol ol,
    ol ul,
    ul ol,
    ul ul                { margin-bottom: 0.75em; }
    ol ol:last-child,
    ol ul:last-child,
    ul ol:last-child,
    ul ul:last-child     { margin-bottom: 0; }
    
    table { .stretch; }
    tbody { font-size: 0.85em; }
    thead,
    th {
        .left-aligned;
        .all-caps;
        .title-small;
    }
    th,
    td {
        .push-half;
        .pad-one-right;
        .pad-1e-left;
        width: 25%;
        vertical-align: top;
    }
    tr {
        border-bottom: 1px solid fade(black, 5%);
        transition: background-color 0.3s ease-out;
    }
    tr:hover {
        background-color: fade(white, 20%);
    }
}
.prose-story {
    .forcewrap;
    
    p,
    ul,
    ol,
    dl {
        margin: 0 0 1em;
    }
    
    figure { margin: 1em 0; }
    figcaption {
        &:extend(
            .text-14-17,
            .gray,
            .left-aligned
        );
        padding-top: 0.5rem;
    }
    figure[style="float: left; margin: 0px 20px 20px 0px;"],
    figure[style="float: right; margin: 0px 0px 20px 20px;"] {
        @media (max-width: @sm-max) {
            width: 50%;
            margin-bottom: 0 !important;
            img {
                max-width: 100% !important;
                height: auto !important;
            }
        }
        margin-top: 0.5rem !important;
    }
}
.prose-buttons-parent a {
    .mustard;
    .push-1q;
    .ngap-1q-left;
    .pad-1q;
    .line-bottom;
    .inline-block;
    min-width: 12rem;
    background-image: none !important;
    &:after {
        content: " →";
    }
}
