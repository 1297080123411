/* ============= */
/* = Variables = */
/* ============= */



// Type
@prose-stack:           Tisa Pro, Georgia, Droid Serif, serif;
@prose-webfont-stack:   ff-tisa-web-pro, @prose-stack;
@prose-leading:         1.4445;

@title-stack:           FF Din, Lucida Grande, Helvetica Neue, Helvetica, Droid Sans, sans-serif;
@title-webfont-stack:   ff-din-web, @title-stack;
@title-leading:         1.123;

@mid-leading:           1.222;


// Layout breakpoints

@xxs-min:    0em;
@xs-min:    22.5em;
@sm-min:    37em;
@md-min:    50em;
@lg-min:    62em;
@xl-min:    75em;
@xxl-min:   90em;

@xxs-max:   (@xs-min - 0.01em);
@xs-max:    (@sm-min - 0.01em);
@sm-max:    (@md-min - 0.01em);
@md-max:    (@lg-min - 0.01em);
@lg-max:    (@xl-min - 0.01em);
@xl-max:    (@xxl-min - 0.01em);

@breakpoint_suffixes:  ~'-xs', ~'-sm', ~'-md', ~'-lg', ~'-xl', ~'-xxl';
@breakpoint_widths: @xs-min, @sm-min, @md-min, @lg-min, @xl-min, @xxl-min;



// Function to recursively declare a set of rules
// at every breakpoint, starting with the default
// (no media query) breakpoint
.recurse-breakpoints(@ruleset) {
    .declare-breakpoint(@ruleset);
    .declare-breakpoint(@ruleset, @xs-min, ~'-xs');
    .declare-breakpoint(@ruleset, @sm-min, ~'-sm');
    .declare-breakpoint(@ruleset, @md-min, ~'-md');
    .declare-breakpoint(@ruleset, @lg-min, ~'-lg');
    .declare-breakpoint(@ruleset, @xl-min, ~'-xl');
    .declare-breakpoint(@ruleset, @xxl-min, ~'-xxl');
}
.declare-breakpoint(@ruleset, @suffix: ~'') {
    @ruleset();
}
.declare-breakpoint(@ruleset, @min-width, @suffix) {
    @media (min-width: @min-width) {
        @ruleset();
    }
}



// Colours
@body-text-colour:  #393939;

// @green:       #B8E986;

@black:       #000000;
@gray:        #9B9B9B;
@silver:      #CCCCCC;
@light:       #F1F1F1;
@white:       #FDFDFD;
@blue:        #2980b9;
@red:         #AC3A3A;
@yellow:      #f39c12;
@green:       #008000;
@tangerine:   #CC8000;
@mblue:       #16a085;
@purple:      #8e44ad;
@green-dark:  multiply(@green, @body-text-colour);

// Web identity colours
@blue-light:            #DBF3FF;
@blue-light-text:       #1D303B;
@green-light:           #C7FFBF;
@green-light-text:      #183D12;
@red-light:             #FFDBC4;
@red-light-text:        #3B2012;

// Brand colour palette
@brand-mustard:         #D2AA28;
@brand-mustard-text:    darken(@brand-mustard, 1.5%);
@brand-black:           #000000;
@brand-brown:           #5A503C;
@brand-brown-light:     #A79677;
@brand-olive:           #78733C;
